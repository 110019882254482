import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";


import Services from './services';
import useNotification from "../../utils/useNotification";
import globalConstant from '../../utils/constants';
import Enums from './enums'
import modifyMobileConfig from './helper/modifyMobileConfig';

import { MobileConfig } from "./types";
import { ErrorObj } from "@svan-garaaz/reactsharedlib";








export const useGetMobileConfig = () => {
    return useQuery(
      [Enums.GET_MOBILE_CONFIG_KEY],
      Services.getMobileConfigs
    );
  }


  export const useCreateMobileConfig = () => {
    const queryClient = useQueryClient()
    const toast = useNotification();
    
    return useMutation(Services.createMobileConfig, {
      onError: (error: AxiosError) => {
        const errorObj : ErrorObj = error.response!.data as ErrorObj;
       
         toast({ description: errorObj.errorMsg, status: globalConstant.Error })
      },
  
      onSuccess: (data) => {
        queryClient.setQueryData([Enums.GET_MOBILE_CONFIG_KEY], modifyMobileConfig(data))
      } 
    });
  };


  export const useDeleteConfig = () => {
    const queryClient = useQueryClient()
    const toast = useNotification();
    
    return useMutation(Services.deleteMobileConfig, {
      onError: (error: AxiosError) => {
        const errorObj : ErrorObj = error.response!.data as ErrorObj;
       
         toast({ description: errorObj.errorMsg, status: globalConstant.Error })
      },
  
      onSuccess: (data, variables) => {

        let dataList = queryClient.getQueryData([Enums.GET_MOBILE_CONFIG_KEY]) as MobileConfig[]

        dataList = dataList.filter(data => !variables.data.some(configKey => configKey === data.key))

       queryClient.setQueryData([Enums.GET_MOBILE_CONFIG_KEY], dataList)
      } 
    });
  };



