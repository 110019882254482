import { InputObj } from '@svan-garaaz/reactsharedlib';
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText
  } from '@chakra-ui/react';
  
  
  const generateInputs = (inputObj: InputObj) => {
      return (
        <FormControl isInvalid={inputObj.isInvalid} mb={3} key={inputObj.name}>
          <FormLabel htmlFor={inputObj.name} mb={0}>{inputObj.label}</FormLabel>
          <Input
            id={inputObj.name}
            type={inputObj.type}
            {...inputObj.register}
            size={inputObj.size ?? 'md'}
            placeholder={inputObj?.placeholder}
            onChange={inputObj?.onChange}
            defaultValue={inputObj?.defaultValue}
          />
          {inputObj.error && (
            <FormErrorMessage>{inputObj.error.message}</FormErrorMessage>
          )}
          {inputObj.helperMessage && (<FormHelperText>{inputObj.helperMessage}</FormHelperText>)}
        </FormControl>
      );

  };
  
  export default generateInputs;
  