import { Box, Flex, Grid, Spinner, Text } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";
import { useDailyLiveDashboard } from "./queryHooks";
import { useNavigate } from "react-router-dom";

interface Metric {
	today: number;
	total: number;
	todayPercentage?: string;
	totalPercentage?: string;
	title: string;
	to: string;
}
const Index = () => {
	const { data, isLoading: loading } = useDailyLiveDashboard();
	const todayRegularOrders = data?.regular_orders_today || 1;
	const totalRegularOrders = data?.regular_orders_total || 1;

	const getPercentage = (total: number, base: number) => {
		let percentage = base
			? (Math.round((total / base) * 100 * 10) / 10).toFixed(1)
			: 0;
		return percentage + "%";
	};

	const {
		unhandled_orders_today = 0,
		unhandled_orders_total = 0,
		undelivered_orders_today = 0,
		undelivered_orders_total = 0,
		customer_hold_orders_today = 0,
		customer_hold_orders_total = 0,
		regular_orders_today = 0,
		regular_orders_total = 0,
		trading_orders_today = 0,
		trading_orders_total = 0,
		facilitation_orders_today = 0,
		facilitation_orders_total = 0,
		return_orders_today = 0,
		return_orders_total = 0,
		cbo_orders_today = 0,
		cbo_orders_total = 0,
		cancelled_orders_today = 0,
		cancelled_orders_total = 0,
		order_delivered_today = 0,
		order_delivered_total = 0
	} = data || {};

	const liveDashboard: Metric[] = [
		{
			today: unhandled_orders_today,
			total: unhandled_orders_total,
			title: "Unhandled Orders",
			to: "unhandled"
		},
		{
			today: undelivered_orders_today,
			total: undelivered_orders_total,
			title: "Undelivered Orders",
			to: "undelivered"
		},
		{
			today: customer_hold_orders_today,
			total: customer_hold_orders_total,
			title: "Customer Hold Orders",
			to: "customer-hold"
		},
		{
			today: regular_orders_today,
			total: regular_orders_total,
			title: "Regular Orders",
			to: "regular"
		},
		{
			today: trading_orders_today,
			total: trading_orders_total,
			title: "Trading Orders",
			to: "trading",
			todayPercentage: getPercentage(
				trading_orders_today,
				todayRegularOrders
			),
			totalPercentage: getPercentage(
				trading_orders_total,
				totalRegularOrders
			),
		},
		{
			today: facilitation_orders_today,
			total: facilitation_orders_total,
			title: "Facilitation Orders",
			to:"facilitation",
			todayPercentage: getPercentage(
				facilitation_orders_today,
				todayRegularOrders
			),
			totalPercentage: getPercentage(
				facilitation_orders_total,
				totalRegularOrders
			),
		},
		{
			today: return_orders_today,
			total: return_orders_total,
			title: "Return Orders",
			to:"return",
			todayPercentage: getPercentage(
				return_orders_today,
				todayRegularOrders
			),
			totalPercentage: getPercentage(
				return_orders_total,
				totalRegularOrders
			),
		},
		{
			today: cbo_orders_today,
			total: cbo_orders_total,
			title: "CBO Orders",
			to:"cbo",
			todayPercentage: getPercentage(cbo_orders_today, todayRegularOrders),
			totalPercentage: getPercentage(cbo_orders_total, totalRegularOrders),
		},
		{
			today: cancelled_orders_today,
			total: cancelled_orders_total,
			title: "Cancelled Orders",
			to:"cancelled",
			todayPercentage: getPercentage(
				cancelled_orders_today,
				todayRegularOrders
			),
			totalPercentage: getPercentage(
				cancelled_orders_total,
				totalRegularOrders
			),
		},
		{
			today: order_delivered_today,
			total: order_delivered_total,
			title: "Delivered Orders",
			to:"delivered", 
		},
	];

	return (
		<PageScaffold title={pageTitles.LiveDashboard}>
			{loading ? (
				<Flex justifyContent="center" alignItems="center" minH="100vh">
					<Spinner size="xl" color="blue.500" />
				</Flex>
			) : (
				<Grid
					templateColumns="repeat(auto-fit, minmax(350px, 1fr))"
					justifyItems="center"
					gap={6}
				>
					{liveDashboard.length === 0 ? (
						<Text textAlign="center" w="100%">
							No data available today
						</Text>
					) : (
						liveDashboard.map(
							(
								{
									title,
									today,
									total,
									to,
									todayPercentage,
									totalPercentage,
								},
								index
							) => (
								<Card
									key={index}
									title={title}
									today={today}
									total={total}
									to={to}
									todayPercentage={todayPercentage}
									totalPercentage={totalPercentage}
								/>
							)
						)
					)}
				</Grid>
			)}
		</PageScaffold>
	);
};

export default Index;

const Card = ({
	today,
	total,
	todayPercentage,
	totalPercentage,
	title,
	to
}: Metric) => {
	const navigate = useNavigate()
	return (
		<Flex
			w="full"
			height="60"
			bg="#333333"
			color="white"
			borderRadius="md"
			p={6}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			position="relative"
			transition="transform 200ms ease-out"
			_hover={{ transform: "scale(1.02)" }}
			_active={{ transform: "scale(0.95)" }}
			onClick={()=> navigate(`/orders/${to}`)}
			cursor={"pointer"}
		>
			{totalPercentage && (
				<Flex
					bg="green.300"
					color="green.900"
					px={3}
					py={0.5}
					borderRadius="full"
					position={"absolute"}
					top={"2"}
					right={"2"}
				>
					<Text
						as="span"
						fontWeight="bold"
						fontSize="xs"
						marginRight={"1"}
					>
						{todayPercentage}
					</Text>
					<Text as="span" fontSize="xs" marginRight={"1"}>
						/
					</Text>
					<Text as="span" fontWeight="bold" fontSize="xs">
						{totalPercentage}
					</Text>
				</Flex>
			)}
			<Flex justify="space-between" align="start" mb={4}>
				<Text fontWeight="bold">
					<Text
						as="span"
						fontSize="7xl"
						color="orange.400"
						marginRight={"1"}
					>
						{today}
					</Text>
					<Text as="span" fontSize="3xl" marginRight={"1"}>
						/
					</Text>
					<Text as="span" fontSize="3xl" color="gray.100">
						{total}
					</Text>
				</Text>
			</Flex>
			<Text fontSize="lg" fontWeight="medium" color={"gray.100"}>
				{title.toUpperCase()}
			</Text>
		</Flex>
	);
};
