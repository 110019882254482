import { Box, Button, Input, Select, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PageScaffold from "../../../components/PageScaffold";
import { secondaryColors } from "../../../utils/designSystem";
import { pageTitles } from "../../../utils/paths";
import services from "../services";
import { GaraazTable } from "@svan-garaaz/reactsharedlib";
import globalConstant from "../../../utils/constants";
const CarList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedGeneration, setSelectedGeneration] = useState("");
  const [uniqueGeneration, setUniqueGenerations] = useState([]);
  const [isPartDataFetching, setIsPartDataFetching] = useState(false);
  const [url, setUrl] = useState(`${process.env.REACT_APP_BASE_URL}/v2/cars`);
  const authorizationAPItoken = localStorage.getItem(globalConstant.MC_SUPER_ADMIN) || "";

  useEffect(() => {
    fetchUniqueCarGeneration();
  }, []);

  async function fetchUniqueCarGeneration() {
    const response = await services.getUniqueCarGenerations();
    setUniqueGenerations(response);
  }
  const TableHeading = [
    {
      header: "Generation",
      accessor: "generation",
    },
    {
      header: "Name",
      accessor: "name",
    },
    {
      header: "description",
      accessor: "description",
    },
  ];
  const row = [10, 20, 30, 50, 100, 200, 500];
  const value = 500;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (selectedGeneration.trim() === "") {
      setUrl(`${process.env.REACT_APP_BASE_URL}/v2/spare-parts`);
    } else {
      setUrl(
        `${
          process.env.REACT_APP_BASE_URL
        }/v2/cars?generation=${selectedGeneration.trim()}`
      );
    }
  }

  function handleAddSparePart() {
    navigate(`${pathname}/overview`, { state: { carId: undefined } });
  }

  function handlePartRowClick(row: any) {
    navigate(`${pathname}/overview`, { state: { carId: row._id } });
  }

  return (
    <PageScaffold
      title={pageTitles.car}
      hasButton={true}
      buttonText={
        <Button
          position={"fixed"}
          right={4}
          border={`1px solid ${secondaryColors.secondary_100}`}
          padding={"7px 10px 7px 10px"}
          onClick={handleAddSparePart}
        >
          + Add
        </Button>
      }
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Box display={"flex"}>
          <Select
            placeholder="Select Generation"
            width={"20%"}
            onChange={(e) => {
              setSelectedGeneration(e.target.value);
            }}
          >
            {uniqueGeneration.map((generation) => {
              return <option value={generation}>{generation}</option>;
            })}
          </Select>
          <Button type="submit" ml={5}>
            Search
          </Button>
        </Box>
      </form>
      {isPartDataFetching && <Spinner colorScheme="blue" mt={10}></Spinner>}

      <Box mt={10}>
        <GaraazTable
          columns={TableHeading}
          url={url}
          limits={row}
          defaultRowLimit={value}
          handleRowClick={handlePartRowClick}
          setIsFetching={setIsPartDataFetching}
          authorizationAPItoken={authorizationAPItoken}
        />
      </Box>
    </PageScaffold>
  );
};

export default CarList;
