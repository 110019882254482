import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";


import Services from './services';
import useNotification from "../../utils/useNotification";
import globalConstant from '../../utils/constants';
import Enums from './enums'
import { ErrorObj } from "@svan-garaaz/reactsharedlib";





export const useGetUnverifiedUsers = () => {
    return useQuery<{data: ISingleComponentUser[], totalCount: ITotalCount[]}, ErrorObj>(
      [Enums.GET_UNVERIFIED_USERS],
      Services.getUnverifiedUsers,
    );
}


export const useVerifyUser = () => {
  const queryClient = useQueryClient()
  const toast = useNotification();
  
  return useMutation(Services.verifyUser, {
    onError: (error: AxiosError) => {
      const errorObj : ErrorObj = error.response!.data as ErrorObj;
     
       toast({ description: errorObj.errorMsg, status: globalConstant.Error })
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: [Enums.GET_UNVERIFIED_USERS]})

      let dataList = queryClient.getQueryData([Enums.GET_UNVERIFIED_USERS]) as ISingleComponentUser[]
      dataList = dataList.filter(user =>  user.component !== data.component && user.role !== data.role && user._id !==data._id)
        
      queryClient.setQueryData([Enums.GET_UNVERIFIED_USERS], dataList)

      toast({ description: Enums.USER_VERIFIED_SUCCESS, status: globalConstant.Success })
    } 
  });
};

export const useUnverifyUser = () => {
  const queryClient = useQueryClient()
  const toast = useNotification();
  
  return useMutation(Services.unVerifyUser, {
    onError: (error: AxiosError) => {
      const errorObj : ErrorObj = error.response!.data as ErrorObj;
     
       toast({ description: errorObj.errorMsg, status: globalConstant.Error })
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: [Enums.GET_UNVERIFIED_USERS]})

      let dataList = queryClient.getQueryData([Enums.GET_UNVERIFIED_USERS]) as ISingleComponentUser[]
      dataList = dataList.filter(user =>  user.component !== data.component && user.role !== data.role && user._id !==data._id)
        
      queryClient.setQueryData([Enums.GET_UNVERIFIED_USERS], dataList)

      toast({ description: Enums.USER_UNVERIFIED_SUCCESS, status: globalConstant.Success })
    } 
  });
};