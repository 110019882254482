const endpoints = {
  getOTP: (visitId: string, phoneNumber: string, mobileNumberPrefix: string) =>
    `/otp/?visitId=${visitId}&mobileNumber=${mobileNumberPrefix}${phoneNumber}`,
  visitId: "/visits",
  verifyOTP: (mobileNumber: string, otp: string, mobileNumberPrefix: string) =>
    `/otp/verify?mobileNumber=${mobileNumberPrefix}${mobileNumber}&otp=${otp}&role=MC_SUPER_ADMIN`,
  banners: "/banners",
  mobileConfig: "/mobile/config",
  mobileAds: "/mobile/ads",
  addMedia: "/media",
  deleteMedia: (mediaId: string) => `/media/${mediaId}`,
  getMedia: (mediaId: string) => `/media/${mediaId}`,
  getUnVerifiedUsers: "/v1/users/?isVerified=false",
  unVerifyUser: (userId: string) => `/v1/users/${userId}/unverify`,
  verifyUser: (userId: string) => `/v1/users/${userId}/verify`,
  customerMonthlyMetrics: "/v1/metrics/customers_monthly",
  dailyLiveDashboard: "/v2/metrics/live_dashboard/",
  subscribeCustomer: (status: string, offset: number, limit: number) =>
    `v1/customers?subscriptions=${status}&offset=${offset}&limit=${limit}`,
  getCustomer: (mobileNumber: string) => `/v1/customers/${mobileNumber}`,
  getSupplierInfo: (supplierId: string) => `/v1/suppliers/${supplierId}`,
  createNewSupplier: "/v1/suppliers",
  updateSupplier: (supplierId: any) => `/v1/suppliers/${supplierId}`,
  createCustomer: "/v2/customers",
  createAddress: (customerId: string) =>
    `/v1/customers/${customerId}/addresses`,
  deleteSupplierBranchById: (
    supplierId: string | undefined,
    branchId: string | undefined
  ) => `/v1/suppliers/${supplierId}/branch/${branchId}`,
  getCitiesAndStates: "/utility/state-city",
  editCustomerDetails: (customerId: string) => `/v1/customers/${customerId}`,
  getAllSuppliersV1: "/v1/suppliers",
  getAllSuppliersV2: "/v2/suppliers",
  deleteSupplierAssociationByIds: (customerId: string, supplierId: string) =>
    `/v1/customers/${customerId}/associations/${supplierId}`,
  getBrands: "/v1/brands",
  getPartCategory: "/v1/part-category",
  saveGrazeon: "/v1/grazeons",
  getGrazeon: (grazeonId: any) => `/v1/grazeons/${grazeonId}`,
  updateGrazeon: (grazeonId: any) => `/v1/grazeons/${grazeonId}`,
  deleteGrazeonRole: (grazeonId: string, component: string, role: string) =>
    `/v1/grazeons/${grazeonId}/roles?component=${component}&role=${role}`,
  fetchCarData: `/v1/cars`,
  setBoodmoModel: (carId: any) => `/v1/cars/${carId}/updateBoodmoModel`,
  getSpareParts: `/v2/spare-parts`,
  getCars: `v1/cars`,
  postPartIdentificationRequest: `/v1/part-identification-requests`,
  getUniqueUPNs: `/v1/spare-part/unique-upns`,
  getSparePartById: (sparePartId: any) => `/v1/spare-part/${sparePartId}`,
  getUniqueGenerations: `/v1/cars/unique-generations`,
  getCarById: (carId: any) => `/v1/cars/${carId}`,
  getCarsByQuery: `v2/cars`,
};

export default endpoints;
