import { useMutation, useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";

import { setupAuthAxios } from "../../config/axios";

import globalConstant from "../../utils/constants";
import Enums from "./enums";
import Services from "./services";
import useNotification from "../../utils/useNotification";
import constants from "./constants";
import { ErrorObj, LocationState } from "@svan-garaaz/reactsharedlib";

export const useGetVisitId = () => {
  return useQuery([constants.visitIdKey], Services.getVisitId, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
};

export const useGetOTP = () => {
  const toast = useNotification();
  return useMutation(Services.getOTP, {
    onError: (error: AxiosError) => {
      const errObj: ErrorObj = error.response!.data as ErrorObj;

      toast({ description: errObj.errorMsg, status: Enums.Error });
    },
    onSuccess: (data: AxiosResponse) => {
      toast({
        description: Enums.OTP_SENT_MESSAGE,
        status: Enums.Success,
        title: Enums.OTP_SENT_TITLE,
      });
    },
  });
};

export const useVerifyOTP = () => {
  const toast = useNotification();
  const location = useLocation();
  const navigate = useNavigate();

  let from = (location.state as LocationState)
    ? (location.state as LocationState).from!.pathname
    : "/";

  return useMutation(Services.verifyOTP, {
    onError: (error: AxiosError) => {
      const errorObj: ErrorObj = error.response!.data as ErrorObj;

      toast({ description: errorObj.errorMsg, status: Enums.Error });
    },

    onSuccess: (data) => {
      setupAuthAxios(process.env.REACT_APP_BASE_URL, data.auth);
      localStorage.setItem(globalConstant.MC_SUPER_ADMIN, data.auth);
      localStorage.MC_USER = JSON.stringify(data.user);
      navigate(from, { replace: true });
    },
  });
};
