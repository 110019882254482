import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";


import Services from './services';
import useNotification from "../../utils/useNotification";
import globalConstant from '../../utils/constants';
import Enums from './enums'
import { Banner } from "./types";
import { ErrorObj } from "@svan-garaaz/reactsharedlib";




export const useGetBanners = () => {
    return useQuery<[Banner], ErrorObj>(
      [Enums.GET_BANNER_KEY],
      Services.getBanners,
    );
}


export const useCreateBanner = () => {
  const queryClient = useQueryClient()
    const toast = useNotification();
    
    return useMutation(Services.createBanner, {
      onError: (error: AxiosError) => {
        const errorObj : ErrorObj = error.response!.data as ErrorObj;
       
         toast({ description: errorObj.errorMsg, status: globalConstant.Error })
      },

      onSuccess: (data) => {
        queryClient.invalidateQueries({queryKey: [Enums.GET_BANNER_KEY]})
        toast({ description: Enums.CREATE_BANNER_SUCCESS_MESSAGE, status: globalConstant.Success })
      }
    });
};


export const useDeleteBanner = () => {
  const queryClient = useQueryClient()
  const toast = useNotification();
  
  return useMutation(Services.deleteBanners, {
    onError: (error: AxiosError) => {
      const errorObj : ErrorObj = error.response!.data as ErrorObj;
     
       toast({ description: errorObj.errorMsg, status: globalConstant.Error })
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: [Enums.GET_BANNER_KEY]})
      toast({ description: Enums.DELETE_BANNER_SUCCESS_MESSAGE, status: globalConstant.Success })
    } 
  });
};


export const useUpdateBanner = () => {
  const queryClient = useQueryClient()
  const toast = useNotification();
  
  return useMutation(Services.updateBanners, {
    onError: (error: AxiosError) => {
      const errorObj : ErrorObj = error.response!.data as ErrorObj;
     
       toast({ description: errorObj.errorMsg, status: globalConstant.Error })
    },

    onSuccess: (data, variables ) => {
      data.media = variables.media
      let dataList = queryClient.getQueryData([Enums.GET_BANNER_KEY]) as Banner[]
      dataList = dataList.map(banner => {
        if(banner._id === variables.bannerId){
          return data;
        }

        return banner
      })
        
      queryClient.setQueryData([Enums.GET_BANNER_KEY], dataList)
  
    } 
  });
};