import { VscFileMedia } from "react-icons/vsc";
import { RiListSettingsLine } from "react-icons/ri";
import { BiNews } from "react-icons/bi";
import { FaWarehouse } from "react-icons/fa";
import ROLES from "./roles";

export const paths = {
  home: "/",
  login: "/login",
  mobileConfigurations: "/mobile-configurations",
  adsManagement: "/ads-management",
  mediaManagement: "/media-management",
  intents: "/intents",
  customerMetrics: "/customer-metrics",
  liveDashboard: "/live-dashboard",
  subscriptions: "/Subscriptions",
  feedbacks: "/Feedbacks",
  suppliers: "/Suppliers",
  grazeons: "/Grazeons",
  supplierAdd: "/Suppliers/add",
  customers: "/customers",
  boodmoModel: "/carBoodmoModel",
  sparePartCompatibility: "/spare-part-compatibility",
  sparePart: "spare-part",
  car: "car",
  UPNs: "upns",
  orders: "/orders/:type"
};

export const pageTitles = {
  mediaManagement: "Banner Management",
  mobileConfigurations: "Mobile Configurations",
  adsManagement: "Configure Adverts",
  customerMetrics: "Customer Metrics",
  intents: "Intents",
  LiveDashboard: "Live Dashboard",
  dashboards: "Dashboards",
  subscriptions: "Subscriptions",
  feedbacks: "Feedbacks",
  suppliers: "Suppliers",
  grazeons: "Grazeons",
  customers: "Customers",
  suppliersAdd: "Add Supplier",
  boodmoModel: "Boodmo Model",
  sparePartCompatibilty: "Spare Part Compatibility",
  EditSparePart: "Edit Spare Part",
  sparePart: "Spare Parts",
  addSparePart: "Add Spare Part",
  car: "Cars",
  addCar: "Add Car",
  editCar: "Edit Car",
  UPN: "UPNs",
};

export const NavNames = {
  mediaManagement: "Banners",
  mobileConfigurations: "Configs",
  adsManagement: "Adverts",
  customerMetrics: "Customers",
  intents: "Intents",
  LiveDashboard: "Live Dashboard",
  dashboards: "Dashboards",
  subscriptions: "Subscriptions",
  feedbacks: "Feedbacks",
  suppliers: "Suppliers",
  grazeons: "Grazeons",
  customers: "Customers",
  boodmoModel: "Boodmo Model",
  sparePartCompatibility: "Spare Part Compatibility",
  sparePart: "Spare Parts",
  car: "Cars",
  UPNs: "UPNs",
};

export const pathObject = [
  {
    main: { name: "Dashboards" },
    sub: [
      { name: NavNames.dashboards, path: paths.home, icon: <FaWarehouse /> },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: { name: "Live Dashboard" },
    sub: [
      {
        name: NavNames.LiveDashboard,
        path: paths.liveDashboard,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: {
      name: "Domain",
    },
    sub: [
      {
        name: NavNames.customers,
        path: paths.customers,
        icon: <FaWarehouse />,
      },
      {
        name: NavNames.suppliers,
        path: paths.suppliers,
        icon: <FaWarehouse />,
      },
      {
        name: NavNames.grazeons,
        path: paths.grazeons,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: {
      name: "Boodmo Model",
    },
    sub: [
      {
        name: NavNames.boodmoModel,
        path: paths.boodmoModel,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },

  {
    main: { name: "Subscriptions" },
    sub: [
      {
        name: NavNames.subscriptions,
        path: paths.subscriptions,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: { name: "Feedbacks" },
    sub: [
      {
        name: NavNames.feedbacks,
        path: paths.feedbacks,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },

  {
    main: { name: "Metrics" },
    sub: [
      {
        name: NavNames.customerMetrics,
        path: paths.customerMetrics,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: { name: "Mobile" },
    sub: [
      {
        name: NavNames.mediaManagement,
        path: paths.mediaManagement,
        icon: <VscFileMedia />,
      },
      {
        name: NavNames.mobileConfigurations,
        path: paths.mobileConfigurations,
        icon: <RiListSettingsLine />,
      },
      {
        name: NavNames.adsManagement,
        path: paths.adsManagement,
        icon: <BiNews />,
      },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: { name: "Access Management" },
    sub: [
      { name: NavNames.intents, path: paths.intents, icon: <VscFileMedia /> },
    ],
    rolesAllowed: [ROLES.MC_ADMIN, ROLES.MC_SUPER_ADMIN],
  },
  {
    main: {
      name: "Part Identification",
    },
    sub: [
      {
        name: NavNames.sparePart,
        path: paths.sparePart,
        icon: <FaWarehouse />,
      },
      {
        name: NavNames.car,
        path: paths.car,
        icon: <FaWarehouse />,
      },
      {
        name: NavNames.UPNs,
        path: paths.UPNs,
        icon: <FaWarehouse />,
      },
    ],
    rolesAllowed: [ROLES.MC_SUPER_ADMIN],
  },
];

export default paths;
