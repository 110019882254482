/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useCreateMobileConfig } from '../queryHooks'

import ModalComponent from '../../../components/ModalComponent';
import generateInputs from '../../../components/DynamicForm';
import SubmitAndCloseButton from '../../../components/SubmitAndCloseButton';
import Enums from '../enums';
import globalConstants from '../../../utils/constants';
import schema from '../formSchema';
import { FormValues, InputObj } from '@svan-garaaz/reactsharedlib';


type Props = {
  isOpen: boolean;
  onClose: () => void;
};


const AddConfigModal = (props: Props) => {
    const { mutate: createMobileConfig, isLoading: creatingMobileConfig, isSuccess} = useCreateMobileConfig()
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema) });



  const handleClose = useCallback(() => {
    setValue(Enums.KEY.toLowerCase(), "")
    setValue(Enums.VALUE.toLowerCase(), "")
    props.onClose()
  }, [setValue])

  useEffect(() => {
    if(isSuccess){
        handleClose()
    }
  }, [isSuccess])

  

  const inputObj: InputObj[] = useMemo(
    () => [
      {
        name: Enums.KEY.toLowerCase(),
        label: Enums.KEY,
        type: globalConstants.TEXT,
        register: register(Enums.KEY.toLowerCase(), {
          required: `${Enums.REQUIRED_MESSAGE} ${Enums.KEY.toLowerCase()}`,
        }),
        isInvalid: !!errors.key,
        error: errors?.key,
      },
      {
        name: Enums.VALUE.toLowerCase(),
        label: Enums.VALUE,
        type: globalConstants.TEXT,
        register: register(Enums.VALUE.toLowerCase(), {
          required: `${Enums.REQUIRED_MESSAGE} ${Enums.VALUE.toLowerCase()}`,
        }),
        isInvalid: !!errors.value,
        error: errors?.value,
      },
    ],
    [errors.key, errors.value, register],
  );

  const convertToValidValues = (values: FormValues) => {
    let newValues: {key: string, value: string | number | boolean} = { key: values.key, value: values.value } 
    if (values.value.toLowerCase() === Enums.TRUE) {
      newValues = { key: values.key, value: true };
    }

    if (values.value.toLowerCase() === Enums.FALSE) {
      newValues = { key: values.key, value: false };
    }


    return {[newValues.key] : newValues.value}
  };

  const onSubmit = useCallback((values: FormValues) => {
    createMobileConfig({ data: convertToValidValues(values)})
  }, []);

  const bottomButton = useMemo(() => {
    return (
      <SubmitAndCloseButton
        isDisabled={false}
        isLoading={creatingMobileConfig}
        onSubmit={handleSubmit(onSubmit)}
        onClose={handleClose}
      />
    );
  }, [handleSubmit, onSubmit,handleClose, creatingMobileConfig]);

  return (
    <ModalComponent
      button={bottomButton}
      title={Enums.ADD_CONFIG_TITLE}
      isOpen={props.isOpen}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {inputObj.map((input) => generateInputs(input))}
      </form>
    </ModalComponent>
  );
};

export default AddConfigModal;
