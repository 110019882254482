import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";


import Services from './services';
import useNotification from "../../utils/useNotification";
import globalConstant from '../../utils/constants';
import Enums from './Enums'
import { ErrorObj } from "@svan-garaaz/reactsharedlib";



export const useGetMobileAds = () => {
    return useQuery(
      [Enums.MOBILE_ADS],
      Services.getMobileAds
    );
  }


  export const useCreateAdvert = () => {
    const queryClient = useQueryClient()
      const toast = useNotification();
      
      return useMutation(Services.createMobileAds, {
        onError: (error: AxiosError) => {
          const errorObj : ErrorObj = error.response!.data as ErrorObj;
         
           toast({ description: errorObj.errorMsg, status: globalConstant.Error })
        },
  
        onSuccess: (data) => {
          queryClient.invalidateQueries({queryKey: [Enums.MOBILE_ADS]})
          toast({ description: Enums.CREATE_ADVERT_SUCCESS_MESSAGE, status: globalConstant.Success })
        }
      });
  };

  export const useDeleteAdvert = () => {
    const queryClient = useQueryClient()
    const toast = useNotification();
    
    return useMutation(Services.deleteMobileAds, {
      onError: (error: AxiosError) => {
        const errorObj : ErrorObj = error.response!.data as ErrorObj;
       
         toast({ description: errorObj.errorMsg, status: globalConstant.Error })
      },
  
      onSuccess: (data) => {
       queryClient.setQueryData([Enums.MOBILE_ADS], {})
        toast({ description: Enums.DELETE_ADVERT_SUCCESS_MESSAGE, status: globalConstant.Success })
      } 
    });
  };



