import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PageScaffold from "../../components/PageScaffold";
import { Button } from "@chakra-ui/react";
import { secondaryColors } from "../../utils/designSystem";
import { pageTitles } from "../../utils/paths";
import AddGrazeons from "./components/AddGrazeons";
import GrazeonInfo from "./components/GrazeonInfo";
import { COLUMN_TYPES, GaraazTable } from "@svan-garaaz/reactsharedlib";
import globalConstant from "../../utils/constants";
const Index = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const authorizationAPItoken = localStorage.getItem(globalConstant.MC_SUPER_ADMIN) || "";

  const TableHeading = [
    {
      header: "Name",
      accessor: "user",
      type: COLUMN_TYPES.FIRST_NAME_LAST_NAME_COMBINE,
    },

    {
      header: "Mobile Number",
      accessor: "user.mobileNumber",
      type: COLUMN_TYPES.MOBILE_WITH_SIZE,
    },
    {
      header: "Engagement Type",
      accessor: "user.userSubType",
      type: COLUMN_TYPES.NAME,
    },
  ];
  const row = [10, 20, 30, 50, 100, 150];
  const value = 50;
  const handleAddGrazeon = () => {
    navigate(`${pathname}/add`);
  };
  const handleGrazeonRowClick = (row: any) => {
    navigate(`${pathname}/${row._id}/overview`);
  };

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PageScaffold
            title={pageTitles.grazeons}
            hasButton={true}
            buttonText={
              <Button
                position={"fixed"}
                right={4}
                onClick={handleAddGrazeon}
                border={` 1px solid ${secondaryColors.secondary_100}`}
                padding={"7px 10px 7px 10px"}
              >
                {" "}
                + Add
              </Button>
            }
          >
            <GaraazTable
              columns={TableHeading}
              url={`${process.env.REACT_APP_BASE_URL}/v1/grazeons`}
              limits={row}
              defaultRowLimit={value}
              handleRowClick={handleGrazeonRowClick}
              authorizationAPItoken={authorizationAPItoken}
            />
          </PageScaffold>
        }
      ></Route>
      <Route path="add" element={<AddGrazeons />}></Route>
      <Route path="/:id/overview" element={<GrazeonInfo />}></Route>
    </Routes>
  );
};

export default Index;
