import { Box, Button, Input, Spinner } from "@chakra-ui/react";
import PageScaffold from "../../../components/PageScaffold";
import { pageTitles } from "../../../utils/paths";
import { secondaryColors } from "../../../utils/designSystem";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { COLUMN_TYPES, GaraazTable } from "@svan-garaaz/reactsharedlib";
import globalConstant from "../../../utils/constants";
const SparePartList = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedPart, setSelectedPart] = useState("");
  const [isPartDataFetching, setIsPartDataFetching] = useState(false);
  const authorizationAPItoken = localStorage.getItem(globalConstant.MC_SUPER_ADMIN) || "";

  const [url, setUrl] = useState(
    `${process.env.REACT_APP_BASE_URL}/v2/spare-parts`
  );
  const TableHeading = [
    {
      header: "Part Number",
      accessor: "number",
    },
    {
      header: "UPN",
      accessor: "UPN",
    },
    {
      header: "Part Name",
      accessor: "partName",
    },
    {
      header: "MRP",
      accessor: "mrp",
      type: COLUMN_TYPES.AMOUNT,
      isSortable: true,
    },
  ];
  const row = [10, 20, 30, 50, 100, 200, 500];
  const value = 500;

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (selectedPart.trim() === "") {
      setUrl(`${process.env.REACT_APP_BASE_URL}/v2/spare-parts`);
    } else {
      setUrl(
        `${
          process.env.REACT_APP_BASE_URL
        }/v2/spare-parts?number=${selectedPart.trim()}&compatibilityPopulated=true`
      );
    }
  }

  function handleAddSparePart() {
    navigate(`${pathname}/overview`, { state: { sparePartId: undefined } });
  }

  function handlePartRowClick(row: any) {
    navigate(`${pathname}/overview`, { state: { sparePartId: row._id } });
  }

  return (
    <PageScaffold
      title={pageTitles.sparePart}
      hasButton={true}
      buttonText={
        <Button
          position={"fixed"}
          right={4}
          border={`1px solid ${secondaryColors.secondary_100}`}
          padding={"7px 10px 7px 10px"}
          onClick={handleAddSparePart}
        >
          + Add
        </Button>
      }
    >
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Box display={"flex"}>
          <Input
            width={"20%"}
            type="text"
            value={selectedPart}
            onChange={(e) => {
              setSelectedPart(e.target.value);
            }}
          ></Input>
          <Button type="submit" ml={5}>
            Search
          </Button>
        </Box>
      </form>
      {isPartDataFetching && <Spinner colorScheme="blue" mt={10}></Spinner>}

      <Box mt={10}>
        <GaraazTable
          columns={TableHeading}
          url={url}
          limits={row}
          defaultRowLimit={value}
          handleRowClick={handlePartRowClick}
          setIsFetching={setIsPartDataFetching}
          authorizationAPItoken={authorizationAPItoken}
        />
      </Box>
    </PageScaffold>
  );
};

export default SparePartList;
