import {
  Button,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { BiSave } from "react-icons/bi";
import AddressDetailsFormSupplier from "./AddressDetailsFormSupplier";
import BankingDetailsFormSupplier from "./BankingDetailsFormSupplier";
import BranchesDetailsFormSupplier from "./BranchesDetailsFormSupplier";
import GaraazSpace from "../../../components/GaraazSpace";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import OthersDetailsFormSupplier from "./OthersDetailsFormSupplier";

import { useEffect, useState } from "react";
import { publicAxios as axios } from "../../../config/axios";
import endpoints from "../../../utils/endpoints";
import useNotification from "../../../utils/useNotification";
import globalConstant from "../../../utils/constants";
import { useParams } from "react-router-dom";
import { useGetSupplierInfo } from "../queryHooks";
import PageScaffold from "../../../components/PageScaffold";
import { successColors } from "../../../utils/designSystem";
import { supplierFormData } from "./AddSupplier";
import DiscountTerms from "./DiscountTerms";
import PaymentTerms from "./PaymentTerms";
import LogisticsTerms from "./LogisticsTerms";

import SupplierDocuments from "./SupplierDocuments";

import CodTerms from "./CodTerms";
import { useGetMobileConfig } from "../../Customers/queryHooks";
import SupplierRemarks from "./SupplierRemarks";
import { GaraazSpinner } from "@svan-garaaz/reactsharedlib";

const SupplierInfo = () => {
  const { id } = useParams();
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: any }>(
    {}
  );

  const { data: supplierData, isLoading, isSuccess } = useGetSupplierInfo(id);

  const [branchDetailsTouchedFields, setBranchDetailsTouchedFields] = useState<{
    [key: string]: any;
  }>({});
  const [supplierBusinessNameTitle, setSupplierBusinessNameTitle] = useState(
    supplierData?.businessName
  );
  const [apiCalled, setApiCalled] = useState(false);
  const handleFieldBlur = (field: string) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };
  const isFieldTouched = (field: any) => {
    return touchedFields[field];
  };
  const toast = useNotification();

  const { data: supplierTypes } = useGetMobileConfig();

  const [formData, setFormData] = useState<supplierFormData>({
    supplierId: "",
    basicInfo: {
      onwerFirstName: "",
      ownerLastName: "",
      businessDisplayName: "",
      businessLegalName: "",
      primaryContactNumber: "",
      secondaryContactNumber: "",
      email: "",
      PAN: "",
      GST: "",
      remarks: "",
      rating: "",
      type: "",
    },
    addressDetails: {
      addressLine1: "",
      addressLine2: "",
      state: "",
      pinCode: "",
      city: "",
      country: "",
    },
    bankingDetails: {
      accountNumber: "",
      bankName: "",
      bankBranchAddress: "",
      ifsc: "",
      bankBranchName: "",
      upi: "",
    },
    branches: [],
    businessTerms: {
      discountTerms: [],
      logisticTerms: {
        garaazShare: "",
      },
      paymentTerms: {
        advancePayment: "",
        onInvoicePayment: "",
        postPaidPayment: "",
      },
      codTerms: {
        codDiscount: "",
      },
    },
    documents: [],
    others: {
      brands: [],
      uniqueCode: "",
    },
  });

  useEffect(() => {
    if (supplierData) {
      setSupplierBusinessNameTitle(supplierData?.businessName);
      setFormData({
        supplierId: supplierData._id,
        basicInfo: {
          onwerFirstName: supplierData?.firstName ?? "",
          ownerLastName: supplierData?.lastName ?? "",
          businessDisplayName: supplierData?.businessName ?? "",
          businessLegalName: supplierData?.businessLegalName ?? "",
          primaryContactNumber: supplierData?.mobileNumber?.slice(2) ?? "",
          secondaryContactNumber:
            supplierData?.secondaryContactNumber?.slice(2) ?? "",
          email: supplierData?.email ?? "",
          PAN: supplierData?.pan ?? "",
          GST: supplierData?.gst ?? "",
          remarks: supplierData?.remarks ?? "",
          type: supplierData?.type ?? "",
          rating: supplierData?.rating ?? "",
        },
        addressDetails: {
          addressLine1: supplierData?.address?.addressLine1 ?? "",
          addressLine2: supplierData?.address?.addressLine2 ?? "",
          state: supplierData?.address?.state ?? "",
          pinCode: supplierData?.address?.pinCode ?? "",
          city: supplierData?.address?.city ?? "",
          country: "INDIA",
        },
        bankingDetails: {
          accountNumber: supplierData?.banking?.accountNumber ?? "",
          bankName: supplierData?.banking?.bankName ?? "",
          bankBranchAddress: supplierData?.banking?.bankBranchAddress ?? "",
          ifsc: supplierData?.banking?.ifsc ?? "",
          bankBranchName: supplierData?.banking?.bankBranchName ?? "",
          upi: supplierData?.banking?.upi ?? "",
        },
        branches:
          supplierData?.branches?.map((branch: any) => {
            return {
              _id: branch?._id,
              branchName: branch?.branchName ?? "",
              branchCode: branch?.branchCode ?? "",
              branchContactPerson: branch?.branchContactPerson ?? "",
              primaryNumber: branch?.branchPrimaryMobileNumber ?? "",
              secondaryNumber: branch?.branchSecondaryMobileNumber ?? "",
              addressLine1: branch?.branchAddress?.addressLine1 ?? "",
              addressLine2: branch?.branchAddress?.addressLine2 ?? "",
              state: branch?.branchAddress?.state ?? "",
              pinCode: branch?.branchAddress?.pinCode ?? "",
              city: branch?.branchAddress?.city ?? "",
              country: "INDIA",
            };
          }) ?? [],

        businessTerms: {
          discountTerms: !!supplierData?.businessTerms?.discountTerms.length
            ? supplierData?.businessTerms?.discountTerms?.map(
                (discountterm: any) => {
                  return {
                    _id: discountterm?._id || "",
                    brands: discountterm?.brands || "",
                    partCategory: discountterm?.partCategory || "",
                    purchaseAmount: discountterm?.purchaseAmount || "",
                    fixedDiscount: discountterm?.fixedDiscount || "",
                    todAmount: discountterm?.todAmount || "",
                    period: discountterm?.period || "",
                    todDiscount: discountterm?.todDiscount || "",
                  };
                }
              )
            : [
                {
                  brands: "",
                  partCategory: "",
                  fixedDiscount: "",
                  period: "",
                  purchaseAmount: "",
                  todAmount: "",
                  todDiscount: "",
                },
              ],
          paymentTerms: {
            advancePayment:
              supplierData?.businessTerms?.paymentTerms?.advancePayment || "",
            onInvoicePayment:
              supplierData?.businessTerms?.paymentTerms?.onInvoicePayment || "",
            postPaidPayment:
              supplierData?.businessTerms?.paymentTerms?.postPaidPayment || "",
          },
          logisticTerms: {
            garaazShare:
              supplierData?.businessTerms?.logisticTerms?.garaazShare || "",
          },
          codTerms: {
            codDiscount:
              supplierData?.businessTerms?.codTerms?.codDiscount || "",
          },
        },
        documents: supplierData?.documents,
        others: {
          brands: supplierData?.brands ?? [],
          uniqueCode: supplierData?.uniqueCode ?? "",
        },
      });
    }
  }, [isSuccess, supplierData]);

  const handleBasicInfoValueChange = (
    second:
      | "onwerFirstName"
      | "ownerLastName"
      | "businessDisplayName"
      | "businessLegalName"
      | "primaryContactNumber"
      | "secondaryContactNumber"
      | "email"
      | "PAN"
      | "GST"
      | "remarks"
      | "type",
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setFormData((previousData) => {
      const newData = {
        ...previousData,
        basicInfo: {
          ...previousData.basicInfo,
          [second]: e.target.value,
        },
      };
      return newData;
    });
  };
  const validateBasicInfo = () => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      ["onwerFirstName"]: true,
      ["ownerLastName"]: true,
      ["businessDisplayName"]: true,
      ["businessLegalName"]: true,
      ["mobileNumber"]: true,
      ["secondaryContactNumber"]: true,
      ["email"]: true,
      ["pan"]: true,
      ["gst"]: true,
      ["type"]: true,
    }));
    if (
      formData.basicInfo.onwerFirstName === "" ||
      formData.basicInfo.ownerLastName === "" ||
      formData.basicInfo.businessDisplayName === "" ||
      formData.basicInfo.businessLegalName === ""
    ) {
      toast({
        description: "Please fill All the required fields in Basic Info",
        status: globalConstant.Error,
      });
      return false;
    }

    if (!validateMobileNumber(formData.basicInfo.primaryContactNumber)) {
      toast({
        description: "Please fill valid Primary Contact Number in Basic Info",
        status: globalConstant.Error,
      });
      return false;
    }

    if (
      formData.basicInfo.email !== "" &&
      !validateEmail(formData.basicInfo.email)
    ) {
      toast({
        description: "Please enter valid Email in Basic Info",
        status: globalConstant.Error,
      });
      return false;
    }
    if (
      (formData.basicInfo.secondaryContactNumber !== "" &&
        !validateMobileNumber(formData.basicInfo.secondaryContactNumber)) ||
      formData.basicInfo.secondaryContactNumber ===
        formData.basicInfo.primaryContactNumber
    ) {
      toast({
        description:
          "Please enter valid Secondary Contact Number in Basic Info",
        status: globalConstant.Error,
      });
      return false;
    }

    if (formData.basicInfo.PAN !== "" && formData.basicInfo.PAN.length !== 10) {
      toast({
        description: "Please enter Valid PAN in Basic Info",
        status: globalConstant.Error,
      });
      return false;
    }

    if (formData.basicInfo.GST !== "" && formData.basicInfo.GST.length !== 15) {
      toast({
        description: "Please enter Valid GST in Basic Info",
        status: globalConstant.Error,
      });
      return false;
    }

    if (formData.basicInfo.type === "") {
      toast({
        description: "Please select valid supplier type",
        status: globalConstant.Error,
      });
      return false;
    }

    return true;
  };

  const validateBranchDetails = () => {
    if (formData.branches.length > 0) {
      if (
        formData.branches.some(
          (branch) =>
            branch.branchName === "" ||
            (branch.primaryNumber !== "" &&
              !validateMobileNumber(branch.primaryNumber)) ||
            (branch.secondaryNumber !== "" &&
              !validateMobileNumber(branch.secondaryNumber))
        )
      )
        return false;
      else {
        return true;
      }
    }

    return true;
  };

  const arePreviousDiscountTermsEmpty = () => {
    const previousDiscountTerms = formData.businessTerms?.discountTerms;
    return (
      previousDiscountTerms &&
      previousDiscountTerms.length > 0 &&
      previousDiscountTerms.some((term) =>
        Object.entries(term).every(([key, value]) => key === "_id" || !value)
      )
    );
  };

  const handleUpdateSupplier = async () => {
    if (!validateBasicInfo()) {
      return;
    }

    if (!validateBranchDetails()) {
      toast({
        description: "Please Enter Valid Branch Details",
        status: globalConstant.Error,
      });
      return;
    }

    const previousDiscountTerms = formData.businessTerms?.discountTerms;

    const hasInvalidAmountInDiscountTerms = previousDiscountTerms.some(
      (term: any) =>
        Number(term.period) < 0 ||
        Number(term.todAmount) < 0 ||
        Number(term.purchaseAmount) < 0
    );
    if (hasInvalidAmountInDiscountTerms) {
      toast({
        description: "Please enter a valid number in discount terms.",
        status: globalConstant.Error,
      });
      return;
    }

    const hasInvalidPercentageInDiscountTerms = previousDiscountTerms.some(
      (term: any) =>
        Number(term.fixedDiscount) > 100 ||
        Number(term.fixedDiscount) < 0 ||
        Number(term.todDiscount) > 100 ||
        Number(term.todDiscount) < 0
    );
    if (hasInvalidPercentageInDiscountTerms) {
      toast({
        description: "Please enter a valid percentage in discount terms.",
        status: globalConstant.Error,
      });
      return;
    }

    const paymentTerms = formData.businessTerms?.paymentTerms;
    const hasInvalidPercentageInPaymentTerms =
      paymentTerms &&
      (Number(paymentTerms.advancePayment) > 100 ||
        Number(paymentTerms.advancePayment) < 0 ||
        Number(paymentTerms.onInvoicePayment) > 100 ||
        Number(paymentTerms.onInvoicePayment) < 0 ||
        Number(paymentTerms.postPaidPayment) > 100 ||
        Number(paymentTerms.postPaidPayment) < 0);

    if (hasInvalidPercentageInPaymentTerms) {
      toast({
        description: "Please enter a valid percentage in payment terms.",
        status: globalConstant.Error,
      });
      return;
    }

    const logisticTerms = formData.businessTerms?.logisticTerms;
    if (
      Number(logisticTerms.garaazShare) > 100 ||
      Number(logisticTerms.garaazShare) < 0
    ) {
      toast({
        description: "Please enter a valid percentage in logicstic terms.",
        status: globalConstant.Error,
      });
      return;
    }

    const codTerms = formData.businessTerms?.codTerms;
    if (
      Number(codTerms.codDiscount) > 100 ||
      Number(codTerms.codDiscount) < 0
    ) {
      toast({
        description: "Please enter a valid percentage in cod terms.",
        status: globalConstant.Error,
      });
      return;
    }

    if (arePreviousDiscountTermsEmpty()) {
      if (previousDiscountTerms.length === 1) {
        formData.businessTerms.discountTerms = [];
      }
      if (previousDiscountTerms.length > 1) {
        toast({
          description:
            "Please fill the discount terms before adding or either delete.",
          status: globalConstant.Error,
        });
        return;
      }
    }

    const branches = formData.branches.map((branch) => {
      return {
        _id: branch._id ?? undefined,
        branchName: branch.branchName,
        branchCode: branch.branchCode,
        branchContactPerson: branch.branchContactPerson,
        branchPrimaryMobileNumber: branch.primaryNumber,
        branchSecondaryMobileNumber: branch.secondaryNumber,
        branchAddress: {
          addressLine1:
            branch.addressLine1 === "" ? undefined : branch.addressLine1,
          addressLine2:
            branch.addressLine2 === "" ? undefined : branch.addressLine2,
          state: branch.state === "" ? undefined : branch.state,
          pinCode: branch.pinCode === "" ? undefined : branch.pinCode,
          city: branch.city === "" ? undefined : branch.city,
          country: "INDIA",
        },
      };
    });

    const discountTerms = formData.businessTerms.discountTerms.map(
      (discount_term) => {
        return {
          _id: discount_term._id ? discount_term._id : undefined,
          brands: discount_term.brands ? discount_term.brands : undefined,
          partCategory: discount_term.partCategory
            ? discount_term.partCategory
            : undefined,
          purchaseAmount: discount_term.purchaseAmount
            ? Number(discount_term.purchaseAmount)
            : undefined,
          fixedDiscount: discount_term.fixedDiscount
            ? Number(discount_term.fixedDiscount)
            : undefined,
          todAmount: discount_term.todAmount
            ? Number(discount_term.todAmount)
            : undefined,
          period: discount_term.period
            ? Number(discount_term.period)
            : undefined,
          todDiscount: discount_term.todDiscount
            ? Number(discount_term.todDiscount)
            : undefined,
        };
      }
    );

    let payloadData = {
      userId: supplierData.userId,
      firstName: formData.basicInfo.onwerFirstName,
      lastName: formData.basicInfo.ownerLastName,
      mobileNumber: "91" + formData.basicInfo.primaryContactNumber,
      businessName: formData.basicInfo.businessDisplayName,
      pan: formData.basicInfo.PAN,
      gst: formData.basicInfo.GST,
      ...formData.basicInfo,
      address: formData.addressDetails,
      banking: formData.bankingDetails,
      branches: branches,
      brands: formData.others.brands,
      rating: formData.basicInfo.rating,
      uniqueCode: formData.others.uniqueCode,
      documents: formData.documents.map((document) => {
        return {
          mediaId: document.mediaId,
          name: document.name,
        };
      }),
      businessTerms: {
        discountTerms: discountTerms,
        paymentTerms: {
          advancePayment: formData.businessTerms.paymentTerms.advancePayment
            ? Number(formData.businessTerms.paymentTerms.advancePayment)
            : undefined,
          onInvoicePayment: formData.businessTerms.paymentTerms.onInvoicePayment
            ? Number(formData.businessTerms.paymentTerms.onInvoicePayment)
            : undefined,
          postPaidPayment: formData.businessTerms.paymentTerms.postPaidPayment
            ? Number(formData.businessTerms.paymentTerms.postPaidPayment)
            : undefined,
        },
        logisticTerms: {
          garaazShare: formData.businessTerms.logisticTerms.garaazShare
            ? Number(formData.businessTerms.logisticTerms.garaazShare)
            : undefined,
        },
        codTerms: {
          codDiscount: formData.businessTerms.codTerms.codDiscount
            ? Number(formData.businessTerms.codTerms.codDiscount)
            : undefined,
        },
      },
    };

    payloadData.secondaryContactNumber =
      payloadData.secondaryContactNumber !== ""
        ? "91" + payloadData.secondaryContactNumber
        : payloadData.secondaryContactNumber;

    try {
      const response = await axios({
        method: "PUT",
        url: endpoints.updateSupplier(id),
        data: payloadData,
        headers: {
          Authorization: localStorage.getItem("MC_SUPER_ADMIN"),
        },
      });
      toast({
        description: `Supplier Updated Successfully`,
        status: globalConstant.Success,
      });
      setFormData((previousFormData) => {
        return {
          ...previousFormData,
          branches: response.data.branches.map((branch: any) => {
            return {
              _id: branch?._id,
              branchName: branch?.branchName ?? "",
              branchCode: branch?.branchCode ?? "",
              branchContactPerson: branch?.branchContactPerson ?? "",
              primaryNumber: branch?.branchPrimaryMobileNumber ?? "",
              secondaryNumber: branch?.branchSecondaryMobileNumber ?? "",
              addressLine1: branch?.branchAddress?.addressLine1 ?? "",
              addressLine2: branch?.branchAddress?.addressLine2 ?? "",
              state: branch?.branchAddress?.state ?? "",
              pinCode: branch?.branchAddress?.pinCode ?? "",
              city: branch?.branchAddress?.city ?? "",
              country: "INDIA",
            };
          }),
          businessTerms: {
            discountTerms: !!response.data?.businessTerms?.discountTerms.length
              ? response.data?.businessTerms?.discountTerms?.map(
                  (discountterm: any) => {
                    return {
                      _id: discountterm?._id || "",
                      brands: discountterm?.brands || "",
                      partCategory: discountterm?.partCategory || "",
                      purchaseAmount: discountterm?.purchaseAmount || "",
                      fixedDiscount: discountterm?.fixedDiscount || "",
                      todAmount: discountterm?.todAmount || "",
                      period: discountterm?.period || "",
                      todDiscount: discountterm?.todDiscount || "",
                    };
                  }
                )
              : [
                  {
                    brands: "",
                    partCategory: "",
                    fixedDiscount: "",
                    period: "",
                    purchaseAmount: "",
                    todAmount: "",
                    todDiscount: "",
                  },
                ],
            paymentTerms: {
              advancePayment:
                response.data?.businessTerms?.paymentTerms?.advancePayment ||
                "",
              onInvoicePayment:
                response.data?.businessTerms?.paymentTerms?.onInvoicePayment ||
                "",
              postPaidPayment:
                response.data?.businessTerms?.paymentTerms?.postPaidPayment ||
                "",
            },
            logisticTerms: {
              garaazShare:
                response.data?.businessTerms?.logisticTerms?.garaazShare || "",
            },
            codTerms: {
              codDiscount:
                response.data?.businessTerms?.codTerms?.codDiscount || "",
            },
          },
        };
      });
      setTouchedFields({});
      setBranchDetailsTouchedFields({});
      setSupplierBusinessNameTitle(payloadData.businessDisplayName);
    } catch (e: any) {
      toast({
        description: `${e.response.data.errorMsg}`,
        status: globalConstant.Error,
      });
    }
  };

  const throttle = (fn: () => any, timer: number) => {
    if (apiCalled) return;

    setApiCalled(true);

    setTimeout(async () => {
      await fn();
      setApiCalled(false);
    }, timer);
  };

  const validateMobileNumber = (phoneNumber: string) => {
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(phoneNumber);
  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddDiscountTerms = () => {
    const previousDiscountTerms = formData.businessTerms?.discountTerms;
    if (arePreviousDiscountTermsEmpty()) {
      toast({
        description:
          "Please fill the previous discount terms before adding a new one.",
        status: globalConstant.Error,
      });
      return;
    }

    const hasInvalidAmountInDiscountTerms = previousDiscountTerms.some(
      (term: any) =>
        Number(term.period) < 0 ||
        Number(term.todAmount) < 0 ||
        Number(term.purchaseAmount) < 0
    );
    if (hasInvalidAmountInDiscountTerms) {
      toast({
        description: "Please enter a valid number",
        status: globalConstant.Error,
      });
      return;
    }

    const hasInvalidDiscountPercentage = previousDiscountTerms?.some(
      (term: any) =>
        Number(term.fixedDiscount) > 100 ||
        Number(term.fixedDiscount) < 0 ||
        Number(term.todDiscount) > 100 ||
        Number(term.todDiscount) < 0
    );

    if (hasInvalidDiscountPercentage) {
      toast({
        description: "Please enter valid percentage values.",
        status: globalConstant.Error,
      });
      return;
    }

    const updatedDiscountTerms = previousDiscountTerms
      ? [...previousDiscountTerms]
      : [];

    updatedDiscountTerms.push({
      brands: "",
      partCategory: "",
      purchaseAmount: "",
      fixedDiscount: "",
      todAmount: "",
      period: "",
      todDiscount: "",
    });

    setFormData((prevFormData: any) => ({
      ...prevFormData,
      businessTerms: {
        ...prevFormData.businessTerms,
        discountTerms: updatedDiscountTerms,
      },
    }));
  };

  if (isLoading) {
    return <GaraazSpinner />;
  }
  return (
    <PageScaffold
      title={supplierBusinessNameTitle}
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={() => {
            throttle(handleUpdateSupplier, 200);
          }}
        />
      }
    >
      <Flex
        mb={8}
        borderBottom={"2px solid lightgrey"}
        paddingBottom={"5px"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading as="h2" size="md" mb={2}>
          Basic Info
        </Heading>
      </Flex>

      <GaraazSpace p={4} width={"100%"}>
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="ownerName" flex={1}>
            <GaraazFormLabel text="Owner Name" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl flex={1}>
              <Input
                type="text"
                placeholder="First name"
                name="firstName"
                value={formData.basicInfo.onwerFirstName}
                onChange={(e) => {
                  handleBasicInfoValueChange("onwerFirstName", e);
                }}
                onBlur={() => handleFieldBlur("onwerFirstName")}
              />
              {isFieldTouched("onwerFirstName") &&
                formData.basicInfo.onwerFirstName === "" && (
                  <Text color="red" fontSize={"sm"}>
                    This field is required
                  </Text>
                )}
            </FormControl>
            <FormControl flex={1} ml={5}>
              <Input
                type="text"
                placeholder="Last name"
                name="lastName"
                value={formData.basicInfo.ownerLastName}
                onChange={(e) => {
                  handleBasicInfoValueChange("ownerLastName", e);
                }}
                onBlur={() => handleFieldBlur("ownerLastName")}
              />
              {isFieldTouched("ownerLastName") &&
                formData.basicInfo.ownerLastName === "" && (
                  <Text color="red" fontSize={"sm"}>
                    This field is required
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="businessname" flex={1}>
            <GaraazFormLabel text="BUSINESS NAME" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl flex={1}>
              <Input
                type="text"
                placeholder="Business display name"
                name="businessName"
                value={formData.basicInfo.businessDisplayName}
                onChange={(e) => {
                  handleBasicInfoValueChange("businessDisplayName", e);
                }}
                onBlur={() => handleFieldBlur("businessDisplayName")}
              />
              {isFieldTouched("businessDisplayName") &&
                formData.basicInfo.businessDisplayName === "" && (
                  <Text color="red" fontSize={"sm"}>
                    This field is required
                  </Text>
                )}
            </FormControl>
            <FormControl flex={1} ml={5}>
              <Input
                type="text"
                placeholder="Business legal name"
                name="businessLegalName"
                value={formData.basicInfo.businessLegalName}
                onChange={(e) => {
                  handleBasicInfoValueChange("businessLegalName", e);
                }}
                onBlur={() => handleFieldBlur("businessLegalName")}
              />
              {isFieldTouched("businessLegalName") &&
                formData.basicInfo.businessLegalName === "" && (
                  <Text color="red" fontSize={"sm"}>
                    This field is required
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="contactNumber" flex={1}>
            <GaraazFormLabel text="Primary Contact Number" isRequired={true} />
          </FormControl>

          <FormControl ml={4} width={"75%"}>
            <InputGroup>
              <InputLeftAddon
                children="+91"
                borderColor="lightgray"
                bg="lightgray"
                color="black"
              />
              <Input
                type="number"
                placeholder="Enter primary number"
                width={"100%"}
                name="mobileNumber"
                value={formData.basicInfo.primaryContactNumber}
                maxLength={10}
                minLength={10}
                onChange={(e) => {
                  handleBasicInfoValueChange("primaryContactNumber", e);
                }}
                onBlur={() => handleFieldBlur("mobileNumber")}
              />
            </InputGroup>
            {isFieldTouched("mobileNumber") &&
              (!validateMobileNumber(formData.basicInfo.primaryContactNumber) ||
                formData.basicInfo.primaryContactNumber.length !== 10) && (
                <Text color="red" fontSize={"sm"}>
                  Enter a valid contact number
                </Text>
              )}
          </FormControl>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="contactNumber" flex={1}>
            <GaraazFormLabel
              text="Secondary Contact Number"
              isRequired={false}
            />
          </FormControl>

          <FormControl ml={4} width={"75%"}>
            <InputGroup>
              <InputLeftAddon
                children="+91"
                borderColor="lightgray"
                bg="lightgray"
                color="black"
              />
              <Input
                type="number"
                placeholder="Enter secondary contact number"
                width={"100%"}
                maxLength={10}
                minLength={10}
                name="secondaryContactNumber"
                value={formData.basicInfo.secondaryContactNumber}
                onChange={(e) => {
                  handleBasicInfoValueChange("secondaryContactNumber", e);
                }}
                onBlur={() => handleFieldBlur("secondaryContactNumber")}
              />
            </InputGroup>
            {isFieldTouched("secondaryContactNumber") &&
              formData.basicInfo.secondaryContactNumber !== "" &&
              (!validateMobileNumber(
                formData.basicInfo.secondaryContactNumber
              ) ||
                formData.basicInfo.secondaryContactNumber.length !== 10 ||
                formData.basicInfo.primaryContactNumber ===
                  formData.basicInfo.secondaryContactNumber) && (
                <Text color="red" fontSize={"sm"}>
                  {`Enter a valid contact number${
                    formData.basicInfo.primaryContactNumber ===
                    formData.basicInfo.secondaryContactNumber
                      ? `, Secondary contact number can not be same.`
                      : ``
                  }`}
                </Text>
              )}
          </FormControl>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="email" flex={1}>
            <GaraazFormLabel text="email" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="email"
                placeholder="Enter your email"
                width={"100%"}
                name="email"
                value={formData.basicInfo.email}
                onChange={(e) => {
                  handleBasicInfoValueChange("email", e);
                }}
                onBlur={() => handleFieldBlur("email")}
              />
              {isFieldTouched("email") &&
                formData.basicInfo.email !== "" &&
                !validateEmail(formData.basicInfo.email) && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid email address
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="pan" flex={1}>
            <GaraazFormLabel text="pan" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter PAN number"
                width={"100%"}
                name="pan"
                value={formData.basicInfo.PAN}
                onChange={(e) => {
                  handleBasicInfoValueChange("PAN", e);
                }}
                onBlur={() => handleFieldBlur("pan")}
              />
              {isFieldTouched("pan") &&
                formData.basicInfo.PAN.length !== 10 &&
                formData.basicInfo.PAN !== "" && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid PAN
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="gst" flex={1}>
            <GaraazFormLabel text="Gst" isRequired={false} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                type="text"
                placeholder="Enter GST number"
                width={"100%"}
                name="gst"
                value={formData.basicInfo.GST}
                onChange={(e) => {
                  handleBasicInfoValueChange("GST", e);
                }}
                onBlur={() => handleFieldBlur("gst")}
              />
              {isFieldTouched("gst") &&
                formData.basicInfo.GST.length !== 15 &&
                formData.basicInfo.GST !== "" && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid GST
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>

        <Flex alignItems="center" justify="space-between" width={"70%"} mb={4}>
          <FormControl width={"25%"} id="gstin" flex={1}>
            <GaraazFormLabel text="type" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Select
                placeholder={"Select supplier type"}
                name="type"
                onChange={(e) => {
                  handleBasicInfoValueChange("type", e);
                }}
                onBlur={() => handleFieldBlur("type")}
                value={formData.basicInfo.type}
              >
                {supplierTypes?.supplier_types?.map(
                  (type: string, index: any) => (
                    <option value={type} key={index}>
                      {type}
                    </option>
                  )
                )}
              </Select>
              {isFieldTouched("type") && formData.basicInfo.type === "" && (
                <Text color="red" fontSize={"sm"}>
                  This field is required
                </Text>
              )}
            </FormControl>
          </Flex>
        </Flex>
      </GaraazSpace>

      <Tabs position="relative" variant="styled">
        <TabList borderBottom={"1px solid lightgrey"}>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Address
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Banking Details
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Branches
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Business Terms
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Remarks
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Documents
          </Tab>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Others
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels mt={5}>
          <TabPanel padding={0}>
            <AddressDetailsFormSupplier
              formData={formData}
              setFormData={setFormData}
            />
          </TabPanel>
          <TabPanel padding={0}>
            <BankingDetailsFormSupplier
              formData={formData}
              setFormData={setFormData}
            />
          </TabPanel>
          <TabPanel padding={0} display={"flex"} flexDirection={"column"}>
            {formData.branches.map((branch, index) => {
              return (
                <BranchesDetailsFormSupplier
                  key={index}
                  index={index}
                  formData={formData}
                  setFormData={setFormData}
                  setBranchDetailsTouchedFields={setBranchDetailsTouchedFields}
                  branchDetailsTouchedFields={branchDetailsTouchedFields}
                />
              );
            })}

            <Button
              mt={5}
              ml={"auto"}
              size={"sm"}
              bg="none"
              color={`${successColors.success_100}`}
              aria-label="Add branch"
              onClick={() => {
                if (
                  formData.branches.some(
                    (branch) =>
                      branch.branchName === "" ||
                      !validateMobileNumber(branch.primaryNumber)
                  )
                ) {
                  toast({
                    description:
                      "Please fill all the required fields in branches",
                    status: globalConstant.Error,
                  });
                  return;
                }
                setFormData((previousData) => {
                  let newData = {
                    ...previousData,
                    branches: [
                      ...previousData.branches,

                      {
                        _id: undefined,
                        branchName: "",
                        branchCode: "",
                        branchContactPerson: "",
                        primaryNumber: "",
                        secondaryNumber: "",
                        addressLine1: "",
                        addressLine2: "",
                        state: "",
                        pinCode: "",
                        city: "",
                        country: "INDIA",
                      },
                    ],
                  };
                  return newData;
                });
              }}
              _hover={{ backgroundColor: "#e9ecef !important" }}
              _focus={{ bg: "none", boxShadow: "none" }}
            >
              {" "}
              + Add
            </Button>
          </TabPanel>
          <TabPanel padding={0}>
            <>
              <Heading mb="3" as="h5" size="md">
                Discount Terms
              </Heading>
              {formData.businessTerms?.discountTerms.map((discount, index) => (
                <DiscountTerms
                  formData={formData}
                  setFormData={setFormData}
                  index={index}
                  key={index}
                />
              ))}
              <Stack my="0" py="0" alignSelf={"flex-end"}>
                <Button
                  ml={"auto"}
                  py="0"
                  size={"sm"}
                  bg="none"
                  color={`${successColors.success_100}`}
                  aria-label="Add discount term"
                  _hover={{ backgroundColor: "#e9ecef !important" }}
                  _focus={{ bg: "none", boxShadow: "none" }}
                  onClick={handleAddDiscountTerms}
                >
                  + Add
                </Button>
              </Stack>
              <PaymentTerms formData={formData} setFormData={setFormData} />
              <LogisticsTerms formData={formData} setFormData={setFormData} />
              <CodTerms formData={formData} setFormData={setFormData} />
            </>
          </TabPanel>
          <TabPanel padding={0}>
            <SupplierRemarks
              formData={formData}
              setFormData={setFormData}
              handleBasicInfoValueChange={handleBasicInfoValueChange}
            ></SupplierRemarks>
          </TabPanel>
          <TabPanel padding={0}>
            <SupplierDocuments
              formData={formData}
              setFormData={setFormData}
            ></SupplierDocuments>
          </TabPanel>
          <TabPanel padding={0}>
            <OthersDetailsFormSupplier
              formData={formData}
              setFormData={setFormData}
              isPrefilled={true}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageScaffold>
  );
};

export default SupplierInfo;
