import { createColumnHelper } from "@tanstack/react-table";
import { Box } from "@chakra-ui/react";

import _ from "lodash";

import { pageTitles } from "../../utils/paths";
import { useGetUnverifiedUsers } from "./queryHooks";

import DynamicTable from "../../components/DynamicTable";
import PageScaffold from "../../components/PageScaffold";
import Enums from "./enums";
import IsVerifiedButton from "./component/verifyButton";
import Loading from "../../components/Loading";
import { TData } from "@svan-garaaz/reactsharedlib";

const columnHelper = createColumnHelper<TData>();

const Index = () => {
  const { data: unVerifiedUsers, isLoading } = useGetUnverifiedUsers();

  console.log({ unVerifiedUsers });

  const columns = [
    columnHelper.accessor(_.camelCase(Enums.name), {
      header: Enums.name,
      cell: (info) =>
        `${info.row.original?.firstName} ${info.row.original?.lastName}`,
    }),
    columnHelper.accessor(_.camelCase(Enums.mobileNumber), {
      header: Enums.mobileNumber,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor(_.camelCase(Enums.component), {
      header: Enums.component,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor(_.camelCase(Enums.role), {
      header: Enums.role,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor(_.camelCase(Enums.status), {
      header: Enums.status,
      cell: (info) => {
        console.log(info.row.original);
        return (
          <IsVerifiedButton data={info.row.original as ISingleComponentUser} />
        );
      },
    }),
  ];

  return (
    <PageScaffold title={pageTitles.intents}>
      <Box mt={4}>
        {isLoading ? (
          <Loading />
        ) : (
          <DynamicTable
            columns={columns}
            data={unVerifiedUsers?.data ?? []}
            hideMaker
            noDataText="No New User Intent to be Verified"
          />
        )}
      </Box>
    </PageScaffold>
  );
};

export default Index;
