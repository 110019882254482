import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Select,
  Switch,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { GaraazMediaUploader } from "@svan-garaaz/reactsharedlib";
import { pageTitles } from "../../../utils/paths";
import PageScaffold from "../../../components/PageScaffold";
import GaraazFormLabel from "../../../components/GarrazFormLabel";
import { ChangeEvent, useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import services from "../services";
import useNotification from "../../../utils/useNotification";
import { useGetMobileConfigs } from "../queryHooks";
import { successColors } from "../../../utils/designSystem";
import { useNavigate, useLocation } from "react-router-dom";
import RolesForm from "./RolesForm";

export type GrazeonFormData = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  userSubType: string;
  roles: {
    role: string;
    component: string;
    isVerified: boolean;
  }[];
  picture: any;
  isActive: boolean;
};

const AddGrazeons = () => {
  const toast = useNotification();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [touchedFields, setTouchedFields] = useState<{ [key: string]: any }>(
    {}
  );

  const [rolesTouchedFields, setRolesTouchedFields] = useState<{
    [key: string]: any;
  }>({});

  const { data: mobileConfigs, isLoading } = useGetMobileConfigs();

  const [grazeonUserSubTypes, setGrazeonUserSubTypes] = useState([]);
  const [componentRolesObject, setComponentRolesObject] = useState<{
    [key: string]: any;
  }>({});

  const [isProfilePicUploading, setIsProfilePicLoading] = useState(false);
  const [isProfilePicDeleting, setIsProfilePhotoDeleting] = useState(false);

  const handleFieldBlur = (field: string) => {
    setTouchedFields((prevTouchedFields) => ({
      ...prevTouchedFields,
      [field]: true,
    }));
  };

  const handleRolesFieldBlur = (field: string, index: number) => {
    setRolesTouchedFields((prevTouchedFields) => {
      return {
        ...prevTouchedFields,
        [`${field}-${index}`]: true,
      };
    });
  };
  const isFieldTouched = (field: any) => {
    return touchedFields[field];
  };
  const isRolesFieldTouched = (field: string, index: number) => {
    return rolesTouchedFields[`${field}-${index}`];
  };
  const [formData, setFormData] = useState<GrazeonFormData>({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    userSubType: "",
    roles: [],
    picture: null,
    isActive: true,
  });

  useEffect(() => {
    if (mobileConfigs) {
      const userSubTypes = JSON.parse(mobileConfigs?.userSubTypes);
      const allowedGrazeonUserSubTypes = userSubTypes?.find(
        (item: any) => item.name === "GARAAZ_PERSONNEL"
      );
      setGrazeonUserSubTypes(allowedGrazeonUserSubTypes?.userSubTypes);

      const componentRoles = JSON.parse(mobileConfigs?.component_roles);
      const parsedComponentRoles: { [key: string]: any } = {};
      componentRoles.forEach((item: any) => {
        parsedComponentRoles[item.name] = item.roles;
      });
      setComponentRolesObject(parsedComponentRoles);
    }
  }, [mobileConfigs]);



  const handleMediaInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (formData.picture) {
      handleDeleteProfilePicture();
    }
    const target = event.target as HTMLInputElement;
    const selectedPicture = target.files ? target.files[0] : null;
    if (selectedPicture) {
      setIsProfilePicLoading(true);
      try {
        const formData = new FormData();
        formData.append("media", selectedPicture);
        const response = await services.addMedia(formData);
        setFormData((prevFormData) => {
          return {
            ...prevFormData,
            picture: {
              path: response[0].path,
              _id: response[0]._id,
              originalName: selectedPicture.name,
            },
          };
        });
      } catch (error) {
        toast({
          description: "Error in uploading pic",
          status: "error",
        });
      } finally {
        setIsProfilePicLoading(false);
      }
    }
  };

  const handleDeleteProfilePicture = async () => {
    setIsProfilePhotoDeleting(true);
    try {
      await services.deleteMedia(formData?.picture?._id);

      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          picture: null,
        };
      });
    } catch (error) {
      toast({
        description: "Error in deleting profile pic",
        status: "error",
      });
    } finally {
      setIsProfilePhotoDeleting(false);
    }
  };
  const handleAddRoles = () => {
    if (
      !formData.roles.some((item) => item.component === "" || item.role === "")
    ) {
      setFormData((prevFormData) => {
        return {
          ...prevFormData,
          roles: [
            ...prevFormData.roles,
            {
              role: "",
              component: "",
              isVerified: true,
            },
          ],
        };
      });
    } else {
      toast({
        description: "Please fill previous roles details",
        status: "error",
      });
    }
  };
  const handleDeleteRoles = (index: number) => {
    setRolesTouchedFields((prev) => {
      return {
        ...prev,
        [`role-${index}`]: false,
        [`component-${index}`]: false,
      };
    });
    setFormData((prevFormData) => {
      let newRoles = prevFormData.roles;
      newRoles.splice(index, 1);
      return {
        ...prevFormData,
        roles: newRoles,
      };
    });
  };

  const handleSaveGrazeon = async () => {
    const validateBasicFormDataResult = validateBasicFormData();
    const validateRolesResult = validateRoles();
    if (validateBasicFormDataResult && validateRolesResult) {
      try {
        const response = await services.saveGrazeon({
          ...formData,
          mobileNumber: "91" + formData.mobileNumber,
          picture: formData?.picture?._id,
          isActive:
            formData.userSubType !== "ORDER_HANDLER"
              ? undefined
              : formData.isActive,
        });

        toast({
          description: "Grazeon saved successfully",
          status: "success",
        });
        navigate(
          `${pathname.slice(0, pathname.length - 3)}${response._id}/overview`
        );
      } catch (error: any) {
        toast({
          description: error.response.data.errorMsg.includes("E11000")
            ? "User with this mobile number already exists"
            : "Error in saving Grazeon",
          status: "error",
        });
      }
    } else {
      toast({
        description: "Please fill all the required fields",
        status: "error",
      });
    }
  };

  const validateBasicFormData = () => {
    let res = true;
    if (formData.mobileNumber.length !== 10) {
      res = false;
      handleFieldBlur("mobileNumber");
    }

    if (formData.firstName === "") {
      res = false;
      handleFieldBlur("firstName");
    }

    if (formData.userSubType === "") {
      res = false;
      handleFieldBlur("userSubType");
    }

    return res;
  };

  const validateRoles = () => {
    let res = true;
    formData.roles.forEach((item, index) => {
      if (item.component === "") {
        res = false;
        handleRolesFieldBlur("component", index);
      }
      if (item.role === "") {
        res = false;
        handleRolesFieldBlur("role", index);
      }
    });
    return res;
  };

  return (
    <PageScaffold
      title={pageTitles.grazeons}
      hasButton={true}
      buttonText={
        <IconButton
          position={"fixed"}
          right={4}
          aria-label="saveBtn"
          size="md"
          color={"white"}
          icon={<BiSave size={"1.5rem"} />}
          onClick={handleSaveGrazeon}
        />
      }
    >
      <Flex
        mb={4}
        borderBottom={"2px solid lightgrey"}
        paddingBottom={"5px"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Heading as="h2" size="md" mb={2}>
          Basic Info
        </Heading>
      </Flex>
      <Box width={"100%"} p={4} position={"relative"}>
      <GaraazMediaUploader
        handleDeleteProfilePicture={handleDeleteProfilePicture}
        handleMediaInputChange={handleMediaInputChange}
        allowMimeType={"image/jpeg,image/jpg,image/png"}
        mediaSrc={formData?.picture?.path}
        isMediaDeleting={isProfilePicDeleting}
        isMediaUploading={isProfilePicUploading}
        />
        <Flex alignItems="center" justify="space-between" mb={4} width={"70%"}>
          <FormControl width={"25%"} id="ownerName" flex={1}>
            <GaraazFormLabel text="Name" isRequired={true} />
          </FormControl>
          <Flex width={"75%"}>
            <FormControl flex={1}>
              <Input
                type="text"
                placeholder="First name"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => {
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      firstName: e.target.value,
                    };
                  });
                }}
                onBlur={() => handleFieldBlur("firstName")}
              />
              {isFieldTouched("firstName") && formData.firstName === "" && (
                <Text color="red" fontSize={"sm"}>
                  This field is required
                </Text>
              )}
            </FormControl>
            <FormControl flex={1} ml={5}>
              <Input
                type="text"
                placeholder="Last name"
                name="lastName"
                value={formData.lastName}
                onChange={(e) => {
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      lastName: e.target.value,
                    };
                  });
                }}
              />
            </FormControl>
          </Flex>
        </Flex>
        <Flex width={"70%"} mb={4}>
          <FormControl width={"25%"}>
            <GaraazFormLabel
              text="Mobile Number"
              isRequired={true}
            ></GaraazFormLabel>
          </FormControl>
          <Flex width={"75%"}>
            <FormControl>
              <Input
                placeholder="Mobile Number"
                width={"50%"}
                type="text"
                value={formData.mobileNumber}
                onChange={(e) => {
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      mobileNumber: e.target.value,
                    };
                  });
                }}
                onBlur={() => handleFieldBlur("mobileNumber")}
              ></Input>
              {isFieldTouched("mobileNumber") &&
                (formData.mobileNumber === "" ||
                  formData.mobileNumber.length !== 10) && (
                  <Text color="red" fontSize={"sm"}>
                    Enter a valid mobile number
                  </Text>
                )}
            </FormControl>
          </Flex>
        </Flex>
        <Flex width={"70%"} mb={4}>
          <FormControl width={"25%"}>
            <GaraazFormLabel
              text="Engagement Type"
              isRequired={true}
            ></GaraazFormLabel>
          </FormControl>
          <Flex>
            <FormControl>
              <Select
                bg={"white"}
                placeholder="Select Engagement Type"
                value={formData.userSubType}
                onChange={(e) => {
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      userSubType: e.target.value,
                    };
                  });
                }}
                onBlur={() => handleFieldBlur("userSubType")}
              >
                {grazeonUserSubTypes.map((item: any, index: number) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
              </Select>
              {isFieldTouched("userSubType") && formData.userSubType === "" && (
                <Text color="red" fontSize={"sm"}>
                  This field is required
                </Text>
              )}
            </FormControl>
          </Flex>
        </Flex>
        {formData.userSubType === "ORDER_HANDLER" && (
          <Flex width={"70%"}>
            <FormControl width={"25%"}>
              <GaraazFormLabel
                text="Is Active"
                isRequired={true}
              ></GaraazFormLabel>
            </FormControl>
            <Flex>
              <FormControl>
                <Switch
                  isChecked={formData.isActive}
                  size="lg"
                  onChange={(e) => {
                    setFormData((prevFormData) => {
                      return {
                        ...prevFormData,
                        isActive: e.target.checked,
                      };
                    });
                  }}
                />
              </FormControl>
            </Flex>
          </Flex>
        )}
      </Box>
      <Tabs position="relative" variant="styled" mt={8}>
        <TabList borderBottom={"1px solid lightgrey"}>
          <Tab
            _selected={{ border: "none", outline: "none", boxShadow: "none" }}
          >
            Roles
          </Tab>
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        />
        <TabPanels mt={5}>
          <TabPanel padding={0}>
            {formData.roles.map((item: any, index: number) => {
              return (
                <RolesForm
                  index={index}
                  formData={formData}
                  setFormData={setFormData}
                  handleRolesFieldBlur={handleRolesFieldBlur}
                  componentRolesObject={componentRolesObject}
                  isRolesFieldTouched={isRolesFieldTouched}
                  handleDeleteRoles={handleDeleteRoles}
                  isEditOne={false}
                  key={index}
                  setComponentRolesObject={setComponentRolesObject}
                ></RolesForm>
              );
            })}
            <Button
              onClick={handleAddRoles}
              display={"block"}
              ml={"auto"}
              size={"sm"}
              border={"none"}
              bgColor={"transparent"}
              color={successColors.success_100}
              _hover={{ backgroundColor: "#e9ecef !important" }}
              _focus={{ bg: "none", boxShadow: "none" }}
            >
              {" "}
              + Add
            </Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageScaffold>
  );
};

export default AddGrazeons;
