import { Box, Flex, Spinner } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { pageTitles } from "../../utils/paths";

import { useState } from "react";
import CustomerFeedbacksTab from "../../components/CustomerFeedbacksTab";
import { COLUMN_TYPES, GaraazTable } from "@svan-garaaz/reactsharedlib";
import globalConstant from "../../utils/constants";

const Index = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading] = useState(false);
  const [isError] = useState(false);

  const currentDate = new Date().toISOString().split("T")[0];
  const authorizationAPItoken = localStorage.getItem(globalConstant.MC_SUPER_ADMIN) || "";


  const TableHeading = [
    {
      header: "Date",
      accessor: "createdAt",
      type: COLUMN_TYPES.DATE,
    },
    { header: "Name", accessor: "name", type: COLUMN_TYPES.NAME },
    {
      header: "Comments",
      accessor: "feedback",
      type: COLUMN_TYPES.FEEDBACK,
    },
  ];

  const row = [10, 20, 30, 50, 100, 150];
  const value = 50;

  return (
    <PageScaffold title={pageTitles.feedbacks}>
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minH="100vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      )}
      {isError && <Box>There was an error loading the data.</Box>}

      <Box>
        <CustomerFeedbacksTab onChange={setActiveTab} />
      </Box>

      {activeTab === 0 && (
        <GaraazTable
          columns={TableHeading}

          url={`${process.env.REACT_APP_BASE_URL}/v1/customers/feedbacks?type=collected&createdAt=${currentDate}`}

          limits={row}
          defaultRowLimit={value}
          authorizationAPItoken={authorizationAPItoken}
        />
      )}
      {activeTab === 1 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers/feedbacks?sentiment=positive&type=collected`}
          limits={row}
          defaultRowLimit={value}
          authorizationAPItoken={authorizationAPItoken}
        />
      )}
      {activeTab === 2 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers/feedbacks?sentiment=neutral&type=collected`}
          limits={row}
          defaultRowLimit={value}
          authorizationAPItoken={authorizationAPItoken}
        />
      )}
      {activeTab === 3 && (
        <GaraazTable
          columns={TableHeading}
          url={`${process.env.REACT_APP_BASE_URL}/v1/customers/feedbacks?sentiment=negative&type=collected`}
          limits={row}
          defaultRowLimit={value}
          authorizationAPItoken={authorizationAPItoken}
        />
      )}
    </PageScaffold>
  );
};

export default Index;
